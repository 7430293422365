<template>
  <v-container class="content-container">
    <h1 class="page-header">Privatlivspolitik</h1>
    <p style="font-size: 0.75rem; float: right">Gældende fra 2020-10-23</p>
    <p></p>
    <p>Betingelser mellem</p>
    <p>cvr.dev</p>
    <p>og dig som bruger:</p>
    <p>
      Vi informerer dig her om vores politik vedrørende behandling af personlige
      oplysninger. Vi vil ikke bruge eller dele dine oplysninger på anden måde
      end som beskrevet i denne privatlivspolitik.
    </p>

    <h1 class="text-header">Indsamling og brug af personlige oplysninger</h1>
    <p>
      Ved at oprette en konto på cvr.dev, accepterer du, at vi indsamler,
      overfører, lagrer og bruger dine personlige oplysninger som beskrevet i
      denne politik. Disse oplysninger inkluderer:
    </p>
    <ul class="text-list">
      <li>
        Stamoplysninger, som bruges til at identificere og kontakte dig: Navn og
        e-mail adresse
      </li>
      <li>
        Statistik og log-data som bruges til at forbedre vores service:
        Enheds-id, enhedstype, oplysninger om geoplacering, enheds- og
        forbindelsesoplysninger, benyttet (herunder hvor lang tid),
        fejl-oplysninger, tidspunkt og dato
      </li>
    </ul>
    <p>
      cvr.dev forbeholder sig retten til at anvende alle oplysninger indsamlet
      til statistiske formål. cvr.dev vil forud for sådanne statistiske formål,
      sikre at data anonymiseres, således at de ikke er personhenførbare og
      dermed ikke længere er at anse som personlige oplysninger.
    </p>

    <h1 class="text-header">Cookies</h1>
    <p>
      Websitet anvender "cookies", der er en tekstfil, som gemmes på din
      computer, mobil el. tilsvarende med det formål at genkende den, huske
      indstillinger, udføre statistik og målrette annoncer. Cookies kan ikke
      indeholde skadelig kode som f.eks. virus.
    </p>
    <p>
      <a href="https://minecookies.org/cookiehandtering/">Det er muligt at slette eller blokere for cookies.</a>
    </p>

    <h1 class="text-header">Brug af databehandlere</h1>
    <p>
      Vi forbeholder os ret til at anvende tredjeparts virksomheder og
      enkeltpersoner ("Databehandlere") til at udbyde vores tjeneste. Brugen af
      Databehandlere vil blive anvendt til at sikre levering af tjenesten på
      vores vegne, eller at hjælpe os med at analysere, hvordan vores tjeneste
      bliver brugt. Databehandlere vil have adgang til dine personlige
      oplysninger, men kun for at udføre opgaver på vores vegne og
      Databehandlere er derfor forpligtet til ikke at videregive eller bruge
      oplysningerne til andre formål. Vi vil altid udarbejde en
      databehandleraftale, der sikrer beskyttelse af dine personlige
      oplysninger. Visse af vores Databehandlere er placeret uden for
      EØS-området. Det betyder, at data kan blive overført til tredjelande. Vi
      har dog indgået de nødvendige databehandleraftaler, ligesom vi løbende
      sikrer, at vores leverandører har de nødvendige certificeringer, f.eks.
      Privacy Shield-certificeringen.
    </p>

    <h1 class="text-header">Brug af databehandlere</h1>
    <p>
      Vi vil udlevere dine personlige oplysninger, hvis det kræves ved lov eller
      stævning, eller hvis vi mener, at en sådan handling er nødvendig for at
      overholde loven og rimelige anmodninger for retshåndhævelse eller for at
      beskytte sikkerheden eller integriteten af vores service.
    </p>

    <h1 class="text-header">Forretningstransaktion</h1>
    <p>
      Hvis cvr.dev er involveret i en fusion, overtagelse eller salg af aktiver,
      kan dine personlige oplysninger blive overført. Vi vil give varsel såfremt
      dine personlige oplysninger overføres, og bliver omfattet af en anden
      politik.
    </p>

    <h1 class="text-header">Sikkerhed</h1>
    <p>
      Sikkerheden af dine personlige oplysninger er vigtig for os, men vær
      opmærksom på, at ingen metode til transmission via internettet, eller
      metode til elektronisk opbevaring er 100% sikker. Vi bestræber os på til
      enhver tid at beskytte dine personlige oplysninger med en række
      organisatoriske og tekniske foranstaltninger, men vi kan ikke garantere
      deres absolutte sikkerhed.
    </p>

    <h1 class="text-header">Ændringer i denne privatlivspolitik</h1>
    <p>
      Vi kan opdatere vores privatlivspolitik fra tid til anden. Vi vil
      underrette dig om eventuelle ændringer ved at offentliggøre den nye
      privatlivspolitik på vores hjemmeside. Du rådes til at gennemgå denne
      privatlivspolitik jævnligt for eventuelle ændringer. Ændringer i denne
      privatlivspolitik træder i kraft, så snart de er offentliggjort på
      hjemmesiden.
    </p>

    <h1 class="text-header">Rettigheder for den registrerede</h1>
    <p>
      I henhold til persondataloven og EU persondataforordningen har den
      registrerede person ("Du") visse rettigheder vedrørende cvr.devs
      indsamlede persondata omkring personen. Disse rettigheder er:
    </p>
    <ul class="text-list">
      <li>
        Indsigtsret – Du har, under visse forhold, ret til indsigt i de data som
        cvr.dev opbevarer om din person
      </li>
      <li>
        Ret til berigtigelse af data – Du har ret til at rette fejl eller
        mangler i dt data som cvr.dev behandler om dig
      </li>
      <li>
        Ret til sletning – Du har, under visse forhold, ret til at få slettet
        personoplysninger om din person. Bemærk at de fleste lovlige
        behandlinger af persondata ikke er omfattet af denne rettighed. Såfremt
        du har givet samtykke til behandling af dine persondata kan du trække
        dit samtykke tilbage, hvorved relaterede data slettes
      </li>
      <li>
        Ret til begrænset behandling – Du har i visse tilfælde ret til at
        suspendere behandlingen af dine persondata. Dette vil være gældende
        såfremt rigtigheden af data betvivles, hvis du ønsker kopi af ulovligt
        behandlede data og dermed ikke ønsker disse slettet, hvis du har behov
        for længere opbevaring af data end cvr.dev s formål tilsiger eller hvis
        du bestrider selskabets ret til at behandle dine data og en sådan tvist
        ikke er afgjort
      </li>
      <li>
        Ret til dataportabilitet – Du har, i visse tilfælde, ret til at modtage
        de persondata som cvr.dev har indsamlet omkring din person i et
        struktureret, almindeligt anvendt og maskinlæsbart format, såfremt du
        ønsker dine persondata overført til en anden data ansvarlig
      </li>
      <li>
        Indsigelsesret – Du har ret til, i visse tilfælde, at gøre indsigelse
        mod behandlingen af dine persondata. Hvis du gør indsigelse mod
        behandlingen af dine data vil disse ikke længere blive behandlet, med
        mindre der er vægtigt legitime grunde hertil, eller behandlingen er
        nødvendig for fastlæggelse eller forsvar af et retskrav. Såfremt dine
        data behandles til brug for direkte markedsføring kan du til enhver tid
        gøre indsigelse herimod, hvilket medfører et stop foren sådan
        behandling.
      </li>
    </ul>
    <p>
      Disse rettigheder følger af EU persondataforordningen og/eller lokal
      lovgivning. Disse rettigheder er ikke absolutte rettigheder, men
      rettigheder, som du kan udøve alt efter hvorledes persondata behandles af
      cvr.dev. Ønsker du at udøve ovenstående rettigheder bedes henvendelser ske
      via cvr.devs kontaktperson ved at bruge kontaktinformationen i denne
      politik. cvr.dev vil vurdere de enkelte henvendelser hurtigst muligt.
      Såfremt du ønsker at klage over behandling af dine persondata til en
      ekstern myndighed skal sådanne klager fremsendes til tilsynsmyndigheden i
      dit EU hjemland. I Danmark vil dette være Datatilsynet. cvr.dev anvender
      ikke de registreredes persondata til automatiske afgørelser eller
      profilering.
    </p>

    <h1 class="text-header">Kontaktoplysninger</h1>
    <p>Email: kontakt@cvr.dev</p>

    <h1 class="page-header">Betingelser</h1>
    <p style="font-size: 0.75rem; float: right">Gældende fra 2022-10-26</p>
    <h1 class="text-header">Forbehold</h1>
    <p>
      cvr.dev tager forbehold for fejl i pris samt trykfejl. cvr.dev er ikke
      ansvarlig for nedbrud af cvr.dev, nedbrud i strømforsyning eller
      internetforbindelse, hærværk på systemet (både fysisk såvel som
      computervirus og hacking), misbrug af personoplysninger eller andre
      forhold som er udenfor cvr.dev's kontrol.
    </p>
    <p>
      cvr.dev kan ikke stilles ansvarlig for unøjagtigheder i den data, der
      bliver hentet via cvr.dev's API.
    </p>


    <h1 class="text-header">Priser, betaling og levering</h1>
    <p>
      Alle priser er i Danske Kroner (DKK), og betaling foregår på cvr.dev, vha. betalingsformidleren Stripe.com, med
      mindre andet er aftalt. Vi tager forbehold for eventuelle fejl i vores angivne priser.
    </p>

    <p>
      Abonnementer hos cvr.dev betales automatisk hver termin indtil abonnementet opsiges af kunden.

      Allerede betalte abonnementsperioder refunderes ikke. Det er altid kundens ansvar at opsige abonnementer
      tidsmæssigt, og med tilstrækkelig varsel.
    </p>

    <p>
      Kunder kan til enhver tid og uden begrundelse opsige deres abonnementet. Når et abonnement bliver opsagt forbliver
      det aktivt i den allerede betalte periode.
      Eksempel: ved køb af abonnement d. 20. i måned X og med opsigelse d. 10. i måned X+1, vil adgangen løbe frem til
      d. 20. i måned X+1. Kunder kan afmelde deres abonnement fra deres profilside på cvr.dev.
    </p>

    <h1 class="text-header">Reklamebeskyttelse</h1>
    <p>
      Virksomheder i Danmark har ret til at frabede sig reklamehenvendelser ved
      at tilvælge reklamebeskyttelse. Denne reklamebeskyttelse gælder stadig
      selvom data er hentet fra cvr.dev.
    </p>
    <p>Nedenfor gengiver vi Virks vilkår for brug af data fra CVR:</p>
    <p>
      Virksomheder kan frabede sig reklamehenvendelser. Alle brugere i CVR skal
      opfylde følgende betingelser:
    </p>
    <ul class="text-list">
      <li>Ønsket om reklamebeskyttelse vil blive respekteret.</li>
      <li>
        at de oplysninger, der er registeret om en reklamebeskyttet virksomhed
        eller p-enhed i CVR på Virk, ikke må bruges til direkte markedsføring,
        hvilket indebærer direkte kundekontakt som fx telefonopkald, målrettede
        postudsendelser m.v.
      </li>
      <li>
        at modtageren ved videregivelse af oplysninger om beskyttede enheder til
        tredjemand tydeligt markerer, at virksomheden eller p-enheden er
        beskyttet.
      </li>
    </ul>
    <p>Overtrædelse af ovennævnte kan straffes med bøde.</p>
    <p>
      <a target="_blank" :href="virkTermsUrl">Læs mere her</a>
    </p>

    <h1 class="text-header">Ansvarsfraskrivelse</h1>
    <p>
      cvr.dev kan alene gøres erstatningsansvarlige for de udbudte ydelsers
      pålydende værdi.
    </p>

    <h1 class="text-header">Lovvalg</h1>
    <p>
      Nærværende betingelser er underlagt dansk ret, og eventuelle tvister kan
      alene indbringes for danske domstole.
    </p>

    <h1 class="text-header">Persondatapolitik</h1>
    <p>
      For at du kan indgå aftale med os og bruge cvr.dev, har vi brug for
      følgende oplysninger om dig:
    </p>
    <ul class="text-list">
      <li>E-mailadresse</li>
      <li>Oplysning om, hvad du har købt</li>
    </ul>
    <p>
      Vi behandler dine personoplysninger med det formål at kunne levere ydelsen
      til dig og for at kunne behandle henvendelser vedrørende dit køb.
      Behandlingen sker efter reglerne i vores persondatapolitik for cvr.dev
      ApS. Heri kan du læse mere om, hvordan dine oplysninger behandles, hvornår
      de slettes, og hvilke rettigheder du har som registreret.
    </p>
  </v-container>
</template>

<script>
import config from "@/config.js";

export default {
  data() {
    return {
      docsUrl: config.DocsUrl,
      virkTermsUrl: config.VirkTermsAndConditionsUrl,
    };
  },
};
</script>

<style>
.page-header {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.text-header {
  /* font-size: 3rem; */
  margin-bottom: 1rem;
}

.text-body {
  font-size: 1.5rem;
  line-height: 1.5;
}

.text-list {
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .content-container {
    width: 560px;
  }
}

@media (min-width: 900px) {
  .content-container {
    width: 800px;
  }
}

@media (min-width: 1200px) {
  .content-container {
    width: 960px;
  }
}

@media (min-width: 1600px) {
  .content-container.content-container--big {
    width: 1400px;
  }
}
</style>
